export default {
  common: {
    title: "ระบบตัวแทน",
    logout: "ออกจากระบบ",
    changePassword: "เปลี่ยนรหัสผ่าน"
  },
  depositStatus: {
    SUCCESS: 'การฝากเงินสำเร็จ',
    SUPPLEMENT_SUCCESS: 'การเติมเงินเพิ่มสำเร็จ',
    CLOSED: 'ปิดแล้ว',
    PENDING: 'รอการฝาก'
  },
  questions: {
    mothersName: "ชื่อแม่ของคุณคือ?",
    mothersBirthday: "วันเกิดแม่ของคุณคือ?",
    dadsName: "ชื่อพ่อของคุณคือ?",
    dadsBirthday: "วันเกิดพ่อของคุณคือ?",
    spouseBirthday: "วันเกิดคู่สมรสของคุณคือ?",
    cityBorn: "คุณเกิดที่เมืองใด?",
    highSchool: "ชื่อโรงเรียนมัธยมของคุณคือ?",
    elementarySchool: "ชื่อโรงเรียนประถมของคุณคือ?",
    firstPetName: "ชื่อสัตว์เลี้ยงคนแรกของคุณคือ?",
    firstCar: "ยี่ห้อรถคันแรกของคุณคือ?"
  },
  gameType: {
    SLOT: "สล็อต",
    LIVE: "คนจริง",
    FISH: "เกมส์ตกปลา",
    SPORT: "กีฬา",
    ESPORT: "อีสปอร์ต",
    POKER: "โป๊กเกอร์",
    LOTTERY: "ลอตเตอรี่"
  },
  result: {
    WIN: "ชนะ",
    LOSS: "แพ้",
    DRAW: "เสมอ"
  },
  betStatus: {
    UNSETTLED: "ยังไม่ได้ตัดบัญชี",
    SETTLED: "ตัดบัญชีแล้ว",
    CANCEL: "ยกเลิกแล้ว"
  },
  transferType: {
    DEPOSIT: "ฝากเงิน",
    COMMISSION: "ฝากคอมมิชชัน"
  },
  fields: {
    account: "บัญชี",
    accountInfo: "ข้อมูลบัญชี",
    activeMember: "สมาชิกที่ใช้งาน",
    activePlayer: 'ผู้เล่นที่ใช้งาน',
    activeUsers: "สมาชิกที่ใช้งาน",
    add: "เพิ่ม",
    addBankCard: "เพิ่มบัตรธนาคาร",
    addVirtualCard: "เพิ่มบัตรเสมือน",
    adjust: 'ปรับ',
    adjustAmount: 'จำนวนการปรับ',
    adjustment: "การปรับจำนวน",
    adjustReason: "เหตุผลการปรับ",
    adjustType: 'ประเภทการปรับ',
    affiliate: "ตัวแทน",
    affiliateAccount: "บัญชีตัวแทน",
    affiliateCode: "รหัสพันธมิตร",
    affiliateDeposit: "การเติมเงินระบบ",
    affiliateInfo: "ข้อมูลตัวแทน",
    affiliateLevel: "ระดับตัวแทน",
    affiliateStatus: "สถานะตัวแทน",
    amountOfFirstDeposit: "จำนวนการเติมเงินครั้งแรก",
    answerOne: "คำตอบ 1",
    answerTwo: "คำตอบ 2",
    answerThree: "คำตอบ 3",
    answerSecurityQuestion: "โปรดตอบคำถามความปลอดภัยทั้งหมด",
    appLink: "ลิงก์ดาวน์โหลดแอพตัวแทน",
    balance: "ยอดคงเหลือ",
    bank: "บัตรธนาคาร",
    bankCard: "บัตรธนาคาร",
    bankId: "รหัสธนาคาร",
    bet: "ยอดเดิมพัน",
    betMembers: "จำนวนสมาชิกเดิมพัน",
    betRecord: "บันทึกการเดิมพัน",
    betRecordDetails: "รายละเอียดบันทึกการเดิมพัน",
    betTime: "เวลาเดิมพัน",
    bind: "ผูกพัน",
    binded: "ผูกพันแล้ว",
    bindSecurityQn: "ตั้งคำถามความปลอดภัย",
    bindWithdrawPw: "ตั้งรหัสผ่านการถอน",
    bonus: "โบนัส",
    cancel: "ยกเลิก",
    cardAccount: "บัญชีบัตรธนาคาร",
    cardAddress: "ที่อยู่บัตรธนาคาร",
    cardNumber: "หมายเลขบัตรธนาคาร",
    changeWithdrawPw: "เปลี่ยนรหัสผ่านการถอน",
    clearingSum: 'ยอดการเคลียร์',
    commission: "คอมมิชชัน",
    commissionBalance: "ยอดคงเหลือคอมมิชชัน",
    commissionPercent: 'ร้อยละคอมมิชชัน',
    commissionRate: "อัตราคอมมิชชัน",
    commissionReport: 'รายละเอียดคอมมิชชัน',
    commissionTransfer: "การโอนคอมมิชชัน",
    companyProfit: "กำไรของบริษัท",
    confirm: "ยืนยัน",
    confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
    confirmWithdraw: "ยืนยันการถอน",
    copy: "คัดลอก",
    createAffiliate: "สร้างตัวแทน",
    crypto: "สกุลเงินดิจิทัล",
    currentPassword: "รหัสผ่านปัจจุบัน",
    deduct: 'หัก',
    deposit: "ฝากเงิน",
    depositAmount: "จำนวนการเติมเงิน",
    depositBettingAmount: 'จำนวนการฝาก/การเดิมพัน',
    depositCount: "จำนวนครั้งที่ฝาก",
    depositDate: "เวลาที่ส่งคำสั่ง",
    depositRecord: "บันทึกการเติมเงิน",
    depositUsers: "จำนวนสมาชิกที่เติมเงิน",
    domainAffiliate: "โดเมนพันธมิตร",
    domainApp: "โดเมนมือถือพันธมิตร",
    domainWeb: "โดเมน PC พันธมิตร",
    downlineAffiliate: "ตัวแทนระดับล่าง",
    downlineCommission: 'คอมมิชชันระดับล่าง',
    downlineCommissionRate: 'อัตราคอมมิชชันระดับล่าง',
    downlineMember: "สมาชิกระดับล่าง",
    downlineProfit: 'กำไรระดับล่าง',
    download: "ดาวน์โหลด",
    edit: "แก้ไข",
    editAffiliate: "แก้ไขตัวแทน",
    editRealName: "แก้ไขชื่อจริง",
    email: "อีเมล",
    endDate: "วันที่สิ้นสุด",
    enterTheWithdrawalAmount: "กรุณาใส่จำนวนเงินถอน",
    estimatedAffiliateCommission: "คอมมิชชันตัวแทนโดยประมาณ",
    estimatedMemberCommission: "คอมมิชชันสมาชิกโดยประมาณ",
    ewallet: "กระเป๋าเงินดิจิทัล",
    finalSum: 'ยอดรวมสุดท้าย',
    finishDate: "เวลาที่ประมวลผลคำสั่ง",
    firstDepositAmount: "จำนวนการเติมเงินครั้งแรก",
    firstDepositUsers: "จำนวนผู้เติมเงินครั้งแรก",
    ftd: 'ครั้งแรกที่เติม',
    gameName: "ชื่อเกม",
    gameType: "ประเภทเกม",
    lastLoginTime: "เวลาเข้าสู่ระบบล่าสุด",
    lastMonth: "เดือนที่แล้ว",
    lastMonthTotal: "รวมเดือนที่แล้ว",
    lastWeek: "สัปดาห์ที่แล้ว",
    link: "ลิงก์เชิญ",
    loginName: "ชื่อผู้ใช้",
    loginPassword: "รหัสผ่านเข้าสู่ระบบ",
    member: "สมาชิก",
    memberBetRecords: "บันทึกการเดิมพันสมาชิก",
    memberCommission: 'คอมมิชชันสมาชิก',
    memberInfo: 'ข้อมูลสมาชิก',
    memberProfit: "กำไรตัวแทน",
    memberProfitDownlineProfitUnsettleCommission: "กำไรตัวแทน/กำไรระดับล่าง/ค้างคอมมิชชันเดือนที่แล้ว",
    month: 'เดือน',
    monthlyAffiliateCommission: "คอมมิชชันตัวแทนรายเดือน",
    monthBeforeLastTotal: "รวมเดือนก่อนหน้า",
    monthlyMemberCommission: "คอมมิชชันสมาชิกรายเดือน",
    myAccount: "บัญชีของฉัน",
    realName: "ชื่อจริง",
    name: "ชื่อ",
    netProfit: "กำไรสุทธิ",
    newPassword: "รหัสผ่านใหม่",
    newUsers: "จำนวนสมาชิกลงทะเบียนใหม่",
    ngr: "NGR",
    noData: "ไม่พบข้อมูล",
    operate: "ดำเนินการ",
    operationalData: "ข้อมูลดำเนินการ",
    password: "รหัสผ่าน",
    paymentFee: "ค่าธรรมเนียมการฝากถอน",
    paymentMethod: "วิธีการชำระเงิน",
    paymentName: "ช่องทางการเติมเงิน",
    payout: "จำนวนการจ่ายเงิน",
    personal: "ข้อมูลส่วนตัว",
    personalInfo: "ข้อมูลส่วนตัว",
    platform: "แพลตฟอร์ม",
    platformFee: "ค่าธรรมเนียมแพลตฟอร์ม",
    profit: "กำไรรวม",
    questionOne: "คำถาม 1",
    questionTwo: "คำถาม 2",
    questionThree: "คำถาม 3",
    rebate: "เงินคืน",
    recordTime: "เวลาบันทึก",
    reenterPassword: "กรอกรหัสผ่านอีกครั้ง",
    referralCode: "รหัสอ้างอิง",
    referralLink: "ลิงก์การแนะนำ",
    registerTime: "เวลาลงทะเบียน",
    reset: "รีเซ็ต",
    result: "ผลลัพธ์",
    revenueShare: "รายได้",
    revenueShareRate: "อัตราส่วนรายได้",
    rollover: "เทิร์นโอเวอร์",
    search: "ค้นหา",
    secondLevelAffiliateCommission: "คอมมิชชันตัวแทนระดับ 2",
    securityInfo: "ข้อมูลความปลอดภัย",
    securityQuestion: "คำถามความปลอดภัย",
    select: "เลือก",
    selectACard: "เลือกบัตร",
    selectBankCard: "เลือกบัตรธนาคาร",
    selectUsdtWallet: "กรุณาเลือกบัญชีกระเป๋า USDT",
    serialNumber: "หมายเลขคำสั่ง",
    settleTime: "เวลาประมวลผล",
    settleView: 'ดู',
    settlePay: 'จ่าย',
    settleEdit: 'ปรับ',
    site: "เว็บไซต์",
    startDate: "วันที่เริ่มต้น",
    status: "สถานะ",
    subtotal: 'ยอดรวมย่อย',
    systemAlert: "แจ้งเตือนระบบ",
    telephone: "หมายเลขโทรศัพท์",
    thirdLevelAffiliateCommission: "คอมมิชชันตัวแทนระดับ 3",
    thisMonth: "เดือนนี้",
    thisWeek: "สัปดาห์นี้",
    today: "วันนี้",
    totalBet: "ยอดเดิมพันทั้งหมด",
    totalCompanyProfit: "กำไรบริษัททั้งหมด",
    totalCommission: 'คอมมิชชันทั้งหมด',
    totalCommissionProfit: 'คอมมิชชัน',
    totalDeposit: "ยอดเงินฝากทั้งหมด",
    totalDownlineAffiliate: "ตัวแทนระดับล่างทั้งหมด",
    totalDownlineMember: "สมาชิกระดับล่างทั้งหมด",
    totalPayout: "จำนวนการจ่ายทั้งหมด",
    totalTransfer: "ยอดการโอนทั้งหมด",
    totalWithdraw: "ยอดการถอนทั้งหมด",
    transactionId: "หมายเลขคำสั่งเดิมพัน",
    transfer: "การโอน",
    transferAmount: "จำนวนการโอน",
    transferDate: "เวลาการโอน",
    transferIn: "โอนเข้า",
    transferOut: "โอนออก",
    transferRecord: "บันทึกการโอน",
    transferType: "ประเภทการโอน",
    transferUsers: "จำนวนสมาชิกในการโอน",
    upperName: "ชื่อผู้ระดับสูง",
    usdtWallet: "บัญชีกระเป๋า USDT",
    usdtWalletAddress: "ที่อยู่กระเป๋า USDT",
    unsettleCommission: "ค้างคอมมิชชันเดือนที่แล้ว",
    viewDetails: "ดูรายละเอียด",
    vipLevel: "ระดับ VIP",
    visitsNo: "จำนวนการเข้าชม",
    winLoss: "ชนะ/แพ้",
    withdraw: "ถอน",
    withdrawAmount: "จำนวนเงินถอน",
    withdrawCount: "จำนวนครั้งที่ถอน",
    withdrawPassword: "รหัสผ่านการถอน",
    withdrawPayoutBonus: 'การถอน/จ่ายเงิน/โบนัส/ปรับ',
    withdrawalAmount: "จำนวนการถอน",
    yesterday: "เมื่อวาน"
  },
  message: {
    addSuccess: "เพิ่มสำเร็จ",
    adjustSuccess: "ปรับสำเร็จ",
    bindBankCard: "โปรดผูกพันบัตรธนาคารก่อน",
    bindUsdtWallet: "โปรดผูกพันกระเป๋า USDT ก่อน",
    chineseCharacters: "โปรดป้อนอักขระจีน",
    commissionPaySuccess: "การจ่ายคอมมิชชันสำเร็จ",
    confirmToAdjust: "โปรดยืนยันว่าคุณต้องการปรับเปลี่ยน? เมื่อทำการปรับเปลี่ยนแล้วจะไม่สามารถเปลี่ยนแปลงได้อีก",
    confirmToPay: "โปรดยืนยันว่าคุณต้องการจ่ายคอมมิชชัน?",
    domainAppCopied: "ระบบคัดลอกโดเมนสำหรับแอปพลิเคชันบนมือถือลงคลิปบอร์ดแล้ว",
    domainWebCopied: "ระบบคัดลอกโดเมนสำหรับ PC ลงคลิปบอร์ดแล้ว",
    editSuccess: "แก้ไขสำเร็จ",
    inputRealName: "โปรดป้อนชื่อจริงของสมาชิก",
    emailFormat: "โปรดป้อนรูปแบบอีเมลที่ถูกต้อง",
    inputChineseCharacters: "โปรดป้อนอักขระจีน",
    inputDigits: "โปรดป้อนตัวเลข",
    inputPassword: "โปรดป้อนรหัสผ่าน",
    inputPasswordAgain: "โปรดป้อนรหัสผ่านอีกครั้ง",
    length6To12: "ข้อความต้องมีความยาวระหว่าง 6 ถึง 12 ตัวอักษร",
    lengthShouldBe: "ความยาวควรเป็น",
    passwordLength: "รหัสผ่านต้องมีความยาวมากกว่า 6 และน้อยกว่า 12 ตัวอักษร",
    redirectBankDeposit: "คุณจะถูกเปลี่ยนเส้นทางไปยังเว็บไซต์ของธนาคารเพื่อทำรายการฝากเงิน หลังจากทำธุรกรรมเสร็จสิ้นคุณจะได้รับการแจ้งเตือนที่นี่",
    reenterPassword: "โปรดป้อนรหัสผ่านอีกครั้ง",
    referralLinkCopied: "ลิงก์การแนะนำได้รับการคัดลอกลงคลิปบอร์ดแล้ว",
    remaining: "จำนวนคงเหลือ",
    requiredAffiliateLevel: "โปรดป้อนระดับของตัวแทน",
    requiredAmount: "โปรดป้อนจำนวนเงิน",
    requiredAnswer: "โปรดป้อนคำตอบ",
    requiredCardAccount: "โปรดป้อนหมายเลขบัตรธนาคาร",
    requiredCardAddress: "โปรดป้อนที่อยู่บัตรธนาคาร",
    requiredCardNumber: "โปรดป้อนหมายเลขบัตรธนาคาร",
    requiredCommission: "โปรดป้อนคอมมิชชัน",
    requiredEmail: "โปรดป้อนอีเมล",
    requiredLoginName: "โปรดป้อนชื่อผู้ใช้",
    requiredOldPassword: "โปรดป้อนรหัสผ่านปัจจุบัน",
    requiredPassword: "โปรดป้อนรหัสผ่าน",
    requiredRealName: "โปรดป้อนชื่อจริง",
    requiredRevenueShare: "โปรดป้อนรายได้",
    requiredRollover: "โปรดป้อนมูลค่าการเล่นเท่านั้น",
    requiredTelephone: "โปรดป้อนหมายเลขโทรศัพท์",
    requiredTransferAmount: "โปรดป้อนจำนวนเงินที่ต้องการโอน",
    requiredUsdtWallet: "โปรดป้อนหมายเลขกระเป๋า USDT",
    requiredUserName: "โปรดป้อนชื่อผู้ใช้",
    requiredWithdrawPassword: "โปรดป้อนรหัสผ่านการถอน",
    selectAQuestion: "โปรดเลือกคำถาม",
    selectBankCard: "โปรดเลือกบัตรธนาคาร",
    selectUsdtWallet: "โปรดเลือกหมายเลขบัญชีกระเป๋า USDT",
    setSecurityQn: "โปรดตั้งคำถามความปลอดภัยก่อน",
    setSecAndPw: "โปรดตั้งคำถามความปลอดภัยและรหัสผ่านการถอนก่อน",
    setWithdrawPassword: "โปรดตั้งรหัสผ่านการถอนก่อน",
    singleLimit: "ขีดจำกัดต่อครั้ง",
    success: "สำเร็จ",
    times: "ครั้ง",
    twoPasswordNotMatch: "รหัสผ่านที่ป้อนไม่ตรงกัน",
    unbindConfirm: "โปรดยืนยันว่าคุณต้องการยกเลิกผูกพัน",
    validateAdjustAmountRequired: "โปรดป้อนจำนวนเงินที่ต้องการปรับเปลี่ยน",
    validateAdjustReasonRequired: "โปรดป้อนเหตุผลในการปรับเปลี่ยน",
    validateAdjustTypeRequired: "โปรดเลือกประเภทการปรับเปลี่ยน",
    validateBankCardNumber: "หมายเลขบัตรธนาคารต้องประกอบด้วยตัวเลขเท่านั้น",
    validateCommission: "คอมมิชชันต้องอยู่ระหว่าง 0 และ 1",
    validateNumberOnly: "โปรดป้อนตัวเลขเท่านั้น",
    validateNumberMoreThanOne: "โปรดป้อนตัวเลขที่มากกว่า 1",
    validateRevenueShare: "รายได้ต้องอยู่ระหว่าง 0 และ 1",
    validateUsdtWallet: "หมายเลขกระเป๋า USDT ต้องประกอบด้วยตัวเลขและตัวอักษร",
    withdrawalToday: "การถอนวันนี้",
    inputUSDTAmount: "โปรดป้อนจำนวน USDT",
    inputAmount: "โปรดป้อนจำนวนเงินที่ฝาก",
    selectAmount: "เลือกจำนวนเงินที่ฝาก",
    minDepositeAmount: "จำนวนฝากขั้นต่ำ",
    maxDepositeAmount: "จำนวนฝากสูงสุด",
    currencyRates: "อัตราแลกเปลี่ยนสด",
    understand: "เข้าใจ",
    DepositCompleted: "การฝากเงินเสร็จสมบูรณ์",
    depositNotification1: "คุณจะถูกเปลี่ยนเส้นทางไปยังหน้าเว็บของธนาคารเพื่อทำธุรกรรมฝากเงิน",
    depositNotification2: "หากฝากเงินสำเร็จคุณจะได้รับการแจ้งเตือนที่นี่"
  },
  menu: {
    undefined: "",
    Dashboard: "หน้าแรก",
    "Downline Info": "ข้อมูลสายล่าง",
    Member: "สมาชิก",
    Affiliate: "ตัวแทน",
    "Bet Record": "บันทึกการเดิมพันของสมาชิก",
    "Deposit Record": "บันทึกการเติมเงินของสมาชิก",
    Statistics: "สถิติ",
    "Game Stats": "สถิติเกม",
    "Affiliate Daily Report": "รายงานรายวันของตัวแทน",
    "Affiliate Center": "ศูนย์ตัวแทน",
    Deposit: "เติมเงิน",
    "Bind Bank Cards": "ผูกพันบัตรธนาคาร",
    "Bank Withdrawal": "ถอนเงินจากธนาคาร",
    Transfer: "โอนเงินสำหรับตัวแทน",
    "Referral Management": "การจัดการการแนะนำ",
    "Referral Link": "ลิงก์การแนะนำ",
    "Affiliate Domain": "โดเมนของตัวแทน",
    "Personal Center": "ศูนย์ส่วนบุคคล",
    "Settlement Center": "ศูนย์การตัดยอดค่าคอมมิชชัน"
  },
  error: {
    400: "คำขอไม่ถูกต้อง",
    403: "การเข้าถึงถูกระงับ",
    405: "ไม่อนุญาตให้ใช้วิธีนี้",
    500: "ข้อผิดพลาดของระบบ",
    501: "รหัสคำขอซ้ำ",
    502: "ไม่พบเซิร์ฟเวอร์",
    504: "คำขอบ่อยเกินไป",
    601: "การตรวจสอบโทเค็นล้มเหลว",
    602: "โทเค็นหมดอายุ",
    603: "ไม่พบโทเค็น",
    604: "บัญชีเข้าสู่ระบบแล้ว",
    707: "ยอดเงินไม่เพียงพอ",
    709: "ยอดคอมมิชชันของตัวแทนไม่เพียงพอ",
    800: "การตรวจสอบรหัสยืนยันล้มเหลว",
    801: "รหัสยืนยันหมดอายุ",
    901: "ไม่พบบันทึกตัวแทน",
    902: "ผู้ใช้งานนี้ไม่ใช่ตัวแทน",
    903: "บัญชีนี้เป็นตัวแทน",
    905: "ชื่อจริงยังไม่ได้รับการกรอก",
    1000: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
    1001: "การเข้าสู่ระบบล้มเหลว เนื่องจากบัญชีถูกระงับ",
    1100: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
    1101: "รหัสผ่านเดิมไม่ถูกต้อง",
    1102: "รหัสผ่านการถอนเงินไม่ถูกต้อง",
    1201: "คำตอบไม่ถูกต้อง",
    1308: "ยอดคงเหลือไม่เพียงพอ",
    1311: "อย่าส่งจำนวนเงินถอนเดียวกันในช่วง 24 ชั่วโมง",
    13000: "ไม่พบชื่อโดเมน",
    14000: "ชื่อผู้ใช้ถูกใช้ไปแล้ว :",
    14001: "หมายเลขโทรศัพท์ถูกใช้ไปแล้ว :",
    14002: "อีเมลถูกใช้ไปแล้ว :",
    14003: "ไม่พบรหัสตัวแทน :",
    14004: "รหัสตัวแทนไม่เป็นส่วนของเว็บไซต์นี้ :",
    14005: "ค่าคอมมิชชันตัวแทนต้องน้อยกว่าค่าคอมมิชชันตัวแทนระดับบน :",
    14006: "รายได้ตัวแทนต้องน้อยกว่ารายได้ตัวแทนระดับบน :",
    14007: "การสมัครตัวแทนยังไม่ได้รับการอนุมัติ",
    14008: "ค่าคอมมิชชันตัวแทนต้องมากกว่าค่าคอมมิชชันตัวแทนย่อย :",
    14009: "รายได้ตัวแทนต้องมากกว่ารายได้ตัวแทนย่อย :",
    14100: "ไม่มีสมาชิกล่างของตัวแทนนี้",
    15201: "รหัสผ่านเดิมไม่ถูกต้อง",
    15202: "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม"
  }
};
