export default {
  common: {
    title: "Affiliate System",
    logout: "Logout",
    changePassword: "Change Password"
  },
  depositStatus: {
    SUCCESS: 'Success',
    SUPPLEMENT_SUCCESS: 'Supplement Success',
    CLOSED: 'Closed',
    PENDING: 'Pending'
  },
  questions: {
    mothersName: "What is your mother's name?",
    mothersBirthday: "When is your mother's birthday?",
    dadsName: "What is your father's name?",
    dadsBirthday: "When is your father's birthday?",
    spouseBirthday: "When is your spouse's birthday?",
    cityBorn: "What city were you born in?",
    highSchool: "What high school did you attend?",
    elementarySchool: "Whatwas the name of your elementary school?",
    firstPetName: "What was the name of your first pet?",
    firstCar: "What is the make of your first car?"
  },
  gameType: {
    SLOT: "SLOT",
    LIVE: "LIVE",
    FISH: "FISH",
    SPORT: "SPORT",
    ESPORT: "ESPORT",
    POKER: "POKER",
    LOTTERY: "LOTTERY"
  },
  result: {
    WIN: "WIN",
    LOSS: "LOSS",
    DRAW: "DRAW"
  },
  betStatus: {
    UNSETTLED: "UNSETTLED",
    SETTLED: "SETTLED",
    CANCEL: "CANCEL"
  },
  transferType: {
    DEPOSIT: "Transfer from Balance",
    COMMISSION: "Transfer from Commission Balance"
  },
  fields: {
    account: "Account",
    accountInfo: "Account Information",
    activeMember: "Active Member",
    activePlayer: 'Active Player',
    activeUsers: "Active Users",
    add: "Add",
    addBankCard: "Add Bank Card",
    addVirtualCard: "Add Virtual Card",
    adjust: 'Adjust',
    adjustAmount: 'Adjust Amount',
    adjustment: "Adjustment",
    adjustReason: "Adjust Reason",
    adjustType: 'Adjust Type',
    affiliate: "Affiliate",
    affiliateAccount: "Affiliate Account",
    affiliateCode: "Affiliate Code",
    affiliateDeposit: "Deposit",
    affiliateInfo: "Affiliate Info",
    affiliateLevel: "Affiliate Level",
    affiliateStatus: "Affiliate Status",
    amountOfFirstDeposit: "Amount of First Deposit",
    answerOne: "Answer One",
    answerTwo: "Answer Two",
    answerThree: "Answer Three",
    answerSecurityQuestion: "Answer All the Security Question",
    appLink: "Application Download Link",
    balance: "Balance",
    bank: "Bank",
    bankCard: "Bank Card",
    bankId: "Bank ID",
    bet: "Bet",
    betMembers: "Bet Members",
    betRecord: "Bet Record",
    betRecordDetails: "Bet Record Details",
    betTime: "Bet Time",
    bind: "Bind",
    binded: "Binded",
    bindSecurityQn: "Setup Security Question",
    bindWithdrawPw: "Setup Withdraw Password",
    bonus: "Bonus",
    cancel: "Cancel",
    cardAccount: "Card Account",
    cardAddress: "Card Address",
    cardNumber: "Card Number",
    changeWithdrawPw: "Change Withdraw Password",
    clearingSum: 'Clearing Sum',
    commission: "Commission",
    commissionBalance: "Commission Balance",
    commissionPercent: 'Commission Percent',
    commissionRate: "Commission Rate",
    commissionReport: 'Commission Report',
    commissionTransfer: "Commission Transfer",
    companyProfit: "Company Profit",
    confirm: "Confirm",
    confirmNewPassword: "Confirm New Password",
    confirmWithdraw: "Confirm Withdraw",
    copy: "Copy",
    createAffiliate: "Create Affiliate",
    crypto: "Crypto",
    currentPassword: "Current Password",
    deduct: 'Deduct',
    deposit: "Deposit",
    depositAmount: "Deposit Amount",
    depositBettingAmount: '存款/下注金额',
    depositCount: "Deposit Count",
    depositDate: "Deposit Date",
    depositRecord: "Deposit Record",
    depositUsers: "Deposit Users",
    domainAffiliate: "Affiliate Domain",
    domainApp: "PC Domain",
    domainWeb: "Mobile Domain",
    downlineAffiliate: "Downline Affiliate",
    downlineCommission: 'Downline Commission',
    downlineCommissionRate: 'Downline Commission Rate',
    downlineMember: "Downline Member",
    downlineProfit: 'Downline Profit',
    download: "Download",
    edit: "Edit",
    editAffiliate: "Edit Affiliate",
    editRealName: "Update Real Name",
    email: "Email",
    endDate: "End Date",
    enterTheWithdrawalAmount: "Enter the withdrawal amount",
    estimatedAffiliateCommission: "Estimated Affiliate Commission",
    estimatedMemberCommission: "Estimated Member Commission",
    ewallet: "e-Wallet",
    finalSum: 'Final Sum',
    finishDate: "Finish Date",
    firstDepositAmount: "First Deposit Amount",
    firstDepositUsers: "First Deposit Users",
    ftd: 'First Deposit',
    gameName: "Game Name",
    gameType: "Game Type",
    lastLoginTime: "Last Login Time",
    lastMonth: "Last Month",
    lastMonthTotal: "Last Month Total",
    lastWeek: "Last Week",
    link: "Invitation Link",
    loginName: "Login Name",
    loginPassword: "Password",
    member: "Member",
    memberBetRecords: "Member Bet Records",
    memberCommission: 'Member Commission',
    memberProfitDownlineProfitUnsettleCommission: "Member Profit / Downline Profit / Unsettle Commission",
    memberInfo: 'Member Info',
    month: 'Month',
    monthlyAffiliateCommission: "Monthly Affiliate Commission",
    monthBeforeLastTotal: "Month Before Last Total",
    monthlyMemberCommission: "Monthly Member Commission",
    myAccount: "My Account",
    realName: "Real Name",
    name: "Name",
    netProfit: "Net Profit",
    newPassword: "New Password",
    newUsers: "New Users",
    ngr: "NGR",
    noData: "No Data",
    operate: "Operate",
    operationalData: "Operational Data",
    password: "Password",
    paymentFee: "Payment Fee",
    paymentMethod: "Payment Method",
    paymentName: "Payment Name",
    payout: "Payout",
    personal: "Personal",
    personalInfo: "Personal Info",
    platform: "Platform",
    platformFee: "Platform Fee",
    profit: "Profit",
    questionOne: "Question One",
    questionTwo: "Question Two",
    questionThree: "Question Three",
    rebate: "Rebate",
    recordTime: "Record Time",
    reenterPassword: "Re-enter Password",
    referralCode: "Referral Code",
    referralLink: "Referral Link",
    registerTime: "Register Time",
    reset: "Reset",
    result: "Result",
    revenueShare: "Revenue Share",
    revenueShareRate: "Revenue Share Rate",
    rollover: "Rollover",
    search: "Search",
    secondLevelAffiliateCommission: "Second Level Affiliate Commission",
    securityInfo: "Security Info",
    securityQuestion: "Security Question",
    select: "Select",
    selectACard: "Select a card",
    selectBankCard: "Select bank card",
    selectUsdtWallet: "Please select a USDT wallet",
    serialNumber: "Serial Number",
    settleTime: "Settle Time",
    settleView: 'View',
    settlePay: 'Pay',
    settleEdit: 'Edit',
    site: "Site",
    startDate: "Start Date",
    status: "Status",
    subtotal: 'Sub Total',
    systemAlert: "System Alert",
    telephone: "Telephone",
    thirdLevelAffiliateCommission: "Third Level Affiliate Commission",
    thisMonth: "This Month",
    thisWeek: "This Week",
    today: "Today",
    totalBet: "Total Bet",
    totalCompanyProfit: "Total Company Profit",
    totalCommissionProfit: 'Total Commission Profit',
    totalCommission: 'Total Commission',
    totalDeposit: "Total Deposit",
    totalDownlineAffiliate: "Total Downline Affiliate",
    totalDownlineMember: "Total Downline Member",
    totalPayout: "Total Payout",
    totalTransfer: "Total Transfer",
    totalWithdraw: "Total Withdraw",
    transactionId: "Transaction ID",
    transfer: "Transfer",
    transferAmount: "Transfer Amount",
    transferDate: "Transfer Date",
    transferIn: "Transfer In",
    transferOut: "Transfer Out",
    transferRecord: "Transfer Record",
    transferType: "Transfer Type",
    transferUsers: "Transfer Users",
    upperName: "Upper Name",
    usdtWallet: "USDT Wallet",
    usdtWalletAddress: "USDT Wallet Address",
    unsettleCommission: "Unsettled Commission",
    viewDetails: "View Details",
    vipLevel: "VIP Level",
    visitsNo: "Number of Visits",
    winLoss: "Win/Loss",
    withdraw: "Withdraw",
    withdrawAmount: "Withdraw Amount",
    withdrawCount: "Withdraw Count",
    withdrawPassword: "Withdraw Password",
    withdrawPayoutBonus: 'Withdraw Payout Bonus Adjust',
    withdrawalAmount: "Withdrawal Amount",
    yesterday: "Yesterday"
  },
  message: {
    adjustSuccess: 'Adjust Success',
    addSuccess: "Add Success",
    bindBankCard: "Please bind bank card first",
    bindUsdtWallet: "Please bind USDT wallet first",
    chineseCharacters: "Please input chinese characters",
    commissionPaySuccess: 'Commission Pay Success',
    confirmToAdjust: 'Are you sure to make adjustment? Unable to make other adjustment after confirmation.',
    confirmToPay: 'Confirm that you want proceed to payment?',
    domainAppCopied: "Web Domain has been copied to clipboard.",
    domainWebCopied: "App Domain has been copied to clipboard.",
    editSuccess: "Edit Success",
    inputRealName: "Member real name has not been set",
    emailFormat: "Please input the correct email address format",
    inputChineseCharacters: "Please input chinese characters",
    inputDigits: "Please input digits",
    inputPassword: "Please input the password",
    inputPasswordAgain: "Please input the password again",
    length6To12: "Length should be 6 to 12",
    lengthShouldBe: "Length should be ",
    passwordLength: "The password cannot be less than 6 digits or more than 12 digits",
    redirectBankDeposit: "You have been redirected to your specific bank to proceed with the deposit. Once the deposit is successful, it will be reflected here.",
    reenterPassword: "Please re-enter the password",
    referralLinkCopied: "Referral link has been copied to clipboard.",
    remaining: "Remaining",
    requiredAffiliateLevel: "Affiliate level is required",
    requiredAmount: "Amount is required",
    requiredAnswer: "Answer is required",
    requiredCardAccount: "Card account is required",
    requiredCardAddress: "Card address is required",
    requiredCardNumber: "Please enter the bank card number",
    requiredCommission: "Commission is required",
    requiredEmail: "Email is required",
    requiredLoginName: "Login name is required",
    requiredOldPassword: "Current Password is required",
    requiredPassword: "Password is required",
    requiredRealName: "Real name is required",
    requiredRevenueShare: "Revenue Share is required",
    requiredRollover: "Rollover is required",
    requiredTelephone: "Telephone is required",
    requiredTransferAmount: "Transfer Amount is required",
    requiredUsdtWallet: "Please enter the USDT wallet",
    requiredUserName: "User name is required",
    requiredWithdrawPassword: "Withdraw Password is required",
    selectAQuestion: "Please select a question",
    selectBankCard: "Please select a bank card",
    selectUsdtWallet: "Please select a USDT wallet",
    setSecurityQn: "Please setup security question",
    setSecAndPw: "Please setup security question and withdraw password",
    setWithdrawPassword: "Please setup withdraw password",
    singleLimit: "Single Limit",
    success: "Success",
    times: "times",
    twoPasswordNotMatch: "Two password does not match",
    unbindConfirm: "Are you sure you want to unbind ",
    validateAdjustAmountRequired: 'Adjust Amount is required',
    validateAdjustReasonRequired: 'Adjust Reason is required',
    validateAdjustTypeRequired: 'Adjust Type is required',
    validateBankCardNumber: "Only number is accepted for bank card number",
    validateCommission: "Commission must be between 0 to 1",
    validateNumberOnly: 'Please enter number only',
    validateNumberMoreThanOne: 'Please enter number more than 1',
    validateRevenueShare: "Revenue Share must be between 0 to 1",
    validateUsdtWallet: "Only alphabet and number is accepted for USDT wallet",
    withdrawalToday: "Withdrawal Today",
    inputUSDTAmount: "Please input USDT amount",
    inputAmount: "Please input amount",
    selectAmount: "Please select amount",
    minDepositeAmount: "Minimum deposit",
    maxDepositeAmount: "Maximum deposit",
    currencyRates: "currency rates",
    understand: "understand",
    DepositCompleted: "Completed",
    depositNotification1: "You will be redirected to your bank's page to complete the deposit.",
    depositNotification2: "If successful, you will receive a notification on this page."
  },
  menu: {
    undefined: "",
    Dashboard: "Dashboard",
    "Downline Info": "Downline Info",
    Member: "Member",
    Affiliate: "Affiliate",
    "Bet Record": "Member Bet Record",
    "Deposit Record": "Member Deposit Record",
    Statistics: "Statistics",
    "Game Stats": "Game Stats",
    "Affiliate Daily Report": "Affiliate Daily Report",
    "Affiliate Center": "Affiliate Center",
    Deposit: "Deposit",
    "Bind Bank Cards": "Bind Bank Cards",
    "Bank Withdrawal": "Bank Withdrawal",
    Transfer: "Transfer",
    "Referral Management": "Referral Management",
    "Referral Link": "Referral Link",
    "Affiliate Domain": "Affiliate Domain",
    "Personal Center": "Personal Center",
    "Settlement Center": "Settlement Center"
  },
  error: {
    400: "Bad Request",
    403: "Forbidden",
    405: "Method Not Allowed",
    500: "System Error",
    501: "Duplicate Request ID",
    502: "Server Not Found",
    504: "Too Often Request",
    601: "Token verification error",
    602: "Token has expired",
    603: "Token is missed",
    604: "Account already login",
    707: "Insufficient balance",
    709: "Insufficient commission balance",
    800: "Captcha verification error",
    801: "Captcha code has expired",
    901: "Affiliate record does not exists",
    902: "Member is not an affiliate",
    903: "Account is an affiliate",
    905: "Real name has not been set",
    1000: "Login name or password is error",
    1001: "Login account is disable",
    1100: "New password cannot be the same as your old password",
    1101: "Old password incorrect",
    1102: "Withdraw password is incorrect",
    1201: "Answer is incorrect",
    1308: "Balance is not enough",
    1311: "Do not submit same withdraw amount within 24 hours",
    13000: "Domain name does not exist",
    14000: "Duplicate login name : ",
    14001: "Duplicate phone number : ",
    14002: "Duplicate email : ",
    14003: "Affiliate code does not exists : ",
    14004: "Affiliate code does not belong to this site : ",
    14005: "Affiliate commission must be less than superior affiliate commission : ",
    14006: "Affiliate revenue must be less than superior affiliate revenue : ",
    14007: "Affiliate application is not being approved yet",
    14008: "Affiliate commission must be more than child affiliate commission : ",
    14009: "Affiliate revenue must be more than child affiliate revenue : ",
    14100: "This member is not your downline member",
    15201: "Old password incorrect",
    15202: "New password cannot be the same as your old password"
  }
};
