export default {
  common: {
    title: "代理系统",
    logout: "登出",
    changePassword: "更改密码"
  },
  depositStatus: {
    SUCCESS: '存款成功',
    SUPPLEMENT_SUCCESS: '存款补单成功',
    CLOSED: '已关闭',
    PENDING: '待存款'
  },
  questions: {
    mothersName: "您母亲的姓名是？",
    mothersBirthday: "您母亲的生日是？",
    dadsName: "您父亲的姓名是？",
    dadsBirthday: "您父亲的生日是？",
    spouseBirthday: "您配偶的生日是？",
    cityBorn: "您出生的城市是？",
    highSchool: "您的中学校名是？",
    elementarySchool: "您的小学校名是？",
    firstPetName: "您第一只宠物的名是？",
    firstCar: "您拥有第一部车的品牌是？"
  },
  gameType: {
    SLOT: "电子",
    LIVE: "真人",
    FISH: "捕鱼",
    SPORT: "体育",
    ESPORT: "电竞",
    POKER: "棋牌",
    LOTTERY: "彩票"
  },
  result: {
    WIN: "赢",
    LOSS: "输",
    DRAW: "和"
  },
  betStatus: {
    UNSETTLED: "未结算",
    SETTLED: "已结算",
    CANCEL: "已取消"
  },
  transferType: {
    DEPOSIT: "额度代存",
    COMMISSION: "佣金代存"
  },
  fields: {
    account: "账号",
    accountInfo: "账号信息",
    activeMember: "活跃会员",
    activePlayer: '活跃玩家',
    activeUsers: "活跃会员",
    add: "新增",
    addBankCard: "新增银行卡",
    addVirtualCard: "新增虚拟卡",
    adjust: '调整',
    adjustAmount: '调整金额',
    adjustment: "调整金额",
    adjustReason: "调整原因",
    adjustType: '平账类型',
    affiliate: "代理",
    affiliateAccount: "代理账号",
    affiliateCode: "合营代码",
    affiliateDeposit: "额度充值",
    affiliateInfo: "代理信息",
    affiliateLevel: "代理级别",
    affiliateStatus: "代理状态",
    amountOfFirstDeposit: "首充金额",
    answerOne: "答案一",
    answerTwo: "答案二",
    answerThree: "答案三",
    answerSecurityQuestion: "请回答全部安全密保问题",
    appLink: "代理后台app下载链接",
    balance: "余额",
    bank: "银行卡",
    bankCard: "银行卡",
    bankId: "银行ID",
    bet: "投注金额",
    betMembers: "下注会员数",
    betRecord: "投注记录",
    betRecordDetails: "注单详情",
    betTime: "投注时间",
    bind: "绑定",
    binded: "已绑定",
    bindSecurityQn: "设置安全密保",
    bindWithdrawPw: "设置支付密码",
    bonus: "红利",
    cancel: "取消",
    cardAccount: "银行卡账号",
    cardAddress: "银行卡地址",
    cardNumber: "银行卡号码",
    changeWithdrawPw: "更改支付密码",
    clearingSum: '结算总和',
    commission: "佣金",
    commissionBalance: "佣金余额",
    commissionPercent: '分红 %',
    commissionRate: "佣金比例",
    commissionReport: '佣金明细',
    commissionTransfer: "佣金代存",
    companyProfit: "公司利润",
    confirm: "确认",
    confirmNewPassword: "确认新密码",
    confirmWithdraw: "确认提款",
    copy: "复制",
    createAffiliate: "新增代理",
    crypto: "数字货币",
    currentPassword: "当前密码",
    deduct: '扣除',
    deposit: "存款",
    depositAmount: "存款金额",
    depositBettingAmount: '存款/投注',
    depositCount: "存款次数",
    depositDate: "订单提交时间",
    depositRecord: "充值记录",
    depositUsers: "充值会员数",
    domainAffiliate: "专属域名",
    domainApp: "移动端专属域名",
    domainWeb: "PC端专属域名",
    downlineAffiliate: "下级代理",
    downlineCommission: '下级分红',
    downlineCommissionRate: '下级分红比率',
    downlineMember: "下级会员",
    downlineProfit: '下线盈利',
    download: "下载",
    edit: "修改",
    editAffiliate: "编辑代理",
    editRealName: "编辑真实名字",
    email: "邮箱",
    endDate: "结束日期",
    enterTheWithdrawalAmount: "请输入提款金额",
    estimatedAffiliateCommission: "预计代理佣金",
    estimatedMemberCommission: "预计会员佣金",
    ewallet: "电子钱包",
    finalSum: '最终佣金',
    finishDate: "订单处理时间",
    firstDepositAmount: "首充金额",
    firstDepositUsers: "首充人数",
    ftd: '首存',
    gameName: "游戏名称",
    gameType: "游戏类型",
    lastLoginTime: "最近登录时间",
    lastMonth: "上月",
    lastMonthTotal: "累计上月",
    lastWeek: "上周",
    link: "邀请链接",
    loginName: "用户名",
    loginPassword: "登录密码",
    member: "会员",
    memberBetRecords: "会员投注记录",
    memberCommission: '会员分红',
    memberInfo: '会员信息',
    memberProfit: "代理盈利",
    memberProfitDownlineProfitUnsettleCommission: "代理盈利/下线盈利/上月结余",
    month: '月份',
    monthlyAffiliateCommission: "本月代理佣金",
    monthBeforeLastTotal: "累计上上月",
    monthlyMemberCommission: "本月会员佣金",
    myAccount: "我的账号",
    realName: "真实名字",
    name: "名字",
    netProfit: "净盈利",
    newPassword: "新密码",
    newUsers: "新注册会员人数",
    ngr: "NGR",
    noData: "查无数据",
    operate: "操作",
    operationalData: "运营数据",
    password: "密码",
    paymentFee: "存提手续费",
    paymentMethod: "支付方式",
    paymentName: "充值渠道",
    payout: "派彩金额",
    personal: "个人资料",
    personalInfo: "个人信息",
    platform: "平台",
    platformFee: "平台费",
    profit: "总盈利",
    questionOne: "问题一",
    questionTwo: "问题二",
    questionThree: "问题三",
    rebate: "返水",
    recordTime: "记录时间",
    reenterPassword: "重新输入密码",
    referralCode: "推荐码",
    referralLink: "推广链接",
    registerTime: "注册时间",
    reset: "重置",
    result: "结果",
    revenueShare: "收入",
    revenueShareRate: "收入比率",
    rollover: "流水倍数",
    search: "搜索",
    secondLevelAffiliateCommission: "二级代理佣金",
    securityInfo: "安全信息",
    securityQuestion: "安全密保",
    select: "选择",
    selectACard: "选择银行卡",
    selectBankCard: "选择银行卡",
    selectUsdtWallet: "请选择虚拟钱包账号",
    serialNumber: "订单号",
    settleTime: "结算时间",
    settleView: '查看',
    settlePay: '发放',
    settleEdit: '调整',
    site: "站点",
    startDate: "开始日期",
    status: "状态",
    subtotal: '小计',
    systemAlert: "系统提示",
    telephone: "电话号码",
    thirdLevelAffiliateCommission: "三级代理佣金",
    thisMonth: "本月",
    thisWeek: "本周",
    today: "今日",
    totalBet: "总投注额",
    totalCompanyProfit: "总公司利润",
    totalCommission: '总分红',
    totalCommissionProfit: '佣金',
    totalDeposit: "总存款",
    totalDownlineAffiliate: "总直属代理",
    totalDownlineMember: "总直属会员",
    totalPayout: "总派彩额",
    totalTransfer: "总代存",
    totalWithdraw: "总提款",
    transactionId: "投注单号",
    transfer: "额度代存",
    transferAmount: "代存金额",
    transferDate: "代存时间",
    transferIn: "转入",
    transferOut: "转出",
    transferRecord: "代存记录",
    transferType: "代存类型",
    transferUsers: "代存会员数",
    upperName: "所属上级",
    usdtWallet: "钱包账号",
    usdtWalletAddress: "钱包地址",
    unsettleCommission: "上月结余",
    viewDetails: "查看详情",
    vipLevel: "VIP等级",
    visitsNo: "访问量",
    winLoss: "盈亏",
    withdraw: "提款",
    withdrawAmount: "提款金额",
    withdrawCount: "提款次数",
    withdrawPassword: "支付密码",
    withdrawPayoutBonus: '提款/派彩/奖金/调整',
    withdrawalAmount: "提款金额",
    yesterday: "昨日"
  },
  message: {
    addSuccess: "新增成功",
    adjustSuccess: '平账成功',
    bindBankCard: "请先绑定银行卡",
    bindUsdtWallet: "请先绑定虚拟币钱包",
    chineseCharacters: "请输入中文字符",
    commissionPaySuccess: '佣金发放成功',
    confirmToAdjust: '请确认是否进行调整？一旦进行调整将无法再更改',
    confirmToPay: '请确认是否发放佣金?',
    domainAppCopied: "移动端专属域名已复制到剪贴板",
    domainWebCopied: "PC端专属域名已复制到剪贴板",
    editSuccess: "编辑成功",
    inputRealName: "会员真实名字未填",
    emailFormat: "请输入正确的邮箱格式",
    inputChineseCharacters: "请输入中文字符",
    inputDigits: "请输入数字",
    inputPassword: "请输入密码",
    inputPasswordAgain: "请再输入密码",
    length6To12: "字符串必须为 6 至 12 字",
    lengthShouldBe: "长度应为 ",
    passwordLength: "密码必须多于 6 字并少于 12 字",
    redirectBankDeposit: "您已被重定向至您的特定银行以继续存款。存款成功后会在此处体现。",
    reenterPassword: "请重新输入密码",
    referralLinkCopied: "推荐链接已复制到剪贴板",
    remaining: "剩余次数",
    requiredAffiliateLevel: "请输入代理级别",
    requiredAmount: "请输入金额",
    requiredAnswer: "请输入答案",
    requiredCardAccount: "请输入银行卡账号",
    requiredCardAddress: "请输入银行卡地址",
    requiredCardNumber: "请输入银行卡号",
    requiredCommission: "请输入佣金",
    requiredEmail: "请输入邮箱",
    requiredLoginName: "请输入用户名",
    requiredOldPassword: "请输入当前密码",
    requiredPassword: "请输入密码",
    requiredRealName: "请输入姓名",
    requiredRevenueShare: "请输入收入",
    requiredRollover: "请输入流水倍数",
    requiredTelephone: "请输入电话号码",
    requiredTransferAmount: "请输入代存金额",
    requiredUsdtWallet: "请输入虚拟钱包账号",
    requiredUserName: "请输入用户名",
    requiredWithdrawPassword: "请输入支付密码",
    selectAQuestion: "请选择问题",
    selectBankCard: "请选择银行卡",
    selectUsdtWallet: "请选择虚拟钱包账号",
    setSecurityQn: "请先设置安全密保",
    setSecAndPw: "请先设置安全密保与支付密码",
    setWithdrawPassword: "请先设置支付密码",
    singleLimit: "单笔限额",
    success: "成功",
    times: "次",
    twoPasswordNotMatch: "重新输入密码不相同",
    unbindConfirm: "请确认是否要解绑 ",
    validateAdjustAmountRequired: '平账金额必填',
    validateAdjustReasonRequired: '平账原因必填',
    validateAdjustTypeRequired: '平账类型必填',
    validateBankCardNumber: "银行卡号只能包含数字",
    validateCommission: "佣金必须介于 0 至 1 之间",
    validateNumberOnly: '请只输入数字',
    validateNumberMoreThanOne: '请输入大于 1 的整数',
    validateRevenueShare: "收入必须介于 0 至 1 之间",
    validateUsdtWallet: "虚拟钱包账号只能包含数字及英文字母",
    withdrawalToday: "今日提款",
    inputUSDTAmount: "输入USDT金额",
    inputAmount: "输入存款金额",
    selectAmount: "选择存款金额",
    minDepositeAmount: "最低存款",
    maxDepositeAmount: "最高存款",
    currencyRates: "实时汇率",
    understand: "理解",
    DepositCompleted: "已存款",
    depositNotification1: "您将被重定向到您的银行页面以完成存款。",
    depositNotification2: "如果成功，您将在此页面上收到通知。"
  },
  menu: {
    undefined: "",
    Dashboard: "首页",
    "Downline Info": "下级信息",
    Member: "会员",
    Affiliate: "代理",
    "Bet Record": "会员投注记录",
    "Deposit Record": "会员充值记录",
    Statistics: "统计",
    "Game Stats": "游戏统计",
    "Affiliate Daily Report": "代理每日报表",
    "Affiliate Center": "代理中心",
    Deposit: "额度充值",
    "Bind Bank Cards": "绑定银行卡",
    "Bank Withdrawal": "银行提款",
    Transfer: "代理代存",
    "Referral Management": "推广管理",
    "Referral Link": "推广链接",
    "Affiliate Domain": "专属域名",
    "Personal Center": "个人中心",
    "Settlement Center": "佣金"
  },
  error: {
    400: "请求无效",
    403: "禁止访问",
    405: "方法不被允许",
    500: "系统错误",
    501: "请求ID重复",
    502: "无法找到伺服器",
    504: "请求太频繁",
    601: "令牌验证失败",
    602: "令牌已逾期",
    603: "无法找到令牌",
    604: "账号已登录",
    707: "余额不足",
    709: "代理佣金余额不足",
    800: "验证码失败",
    801: "验证码已失效",
    901: "代理记录不存在",
    902: "此用户非代理",
    903: "此账号为代理",
    905: "真实名字尚未填写",
    1000: "登录名或密码错误",
    1001: "登录失败, 账号已被禁用",
    1100: "新密码不能与旧密码相同",
    1101: "旧密码不正确",
    1102: "支付密码不正确",
    1201: "答案不正确",
    1308: "余额不足",
    1311: "24小时内勿提交相同提款金额",
    13000: "域名不存在",
    14000: "用户名已被使用 : ",
    14001: "电话号码已被使用 : ",
    14002: "邮箱已被使用 : ",
    14003: "代理代码不存在 : ",
    14004: "代理代码不属于此站点 : ",
    14005: "代理佣金必须少于上线代理佣金 : ",
    14006: "代理收入必须少于上线代理收入 : ",
    14007: "代理申请尚未被批准",
    14008: "代理佣金必须多余子代理佣金 : ",
    14009: "代理收入必须多余子代理收入 : ",
    14100: "代理并无此下级会员",
    15201: "旧密码不正确",
    15202: "新密码不能与旧密码相同"
  }
};
